<template>
  <main>
    <div v-if="investment && offering">
      <div v-if="investment.status != 'draft'">
        <div class="form-outline mb-4 container-fluid d-flex justify-content-center">
          Your investment, <b>{{ investment.investmentName }}</b>, has already been submitted and can no longer be edited.
        </div>
        <div class=" form-outline mb-4 container-fluid">
          <div class="row text-center p-5">
            <div class="col-md-6">
              <router-link :to="{ name: 'Investments', params: { id: offering.id } }" class="btn btn-primary">
                View submitted investments
              </router-link>
            </div>
            <div class="col-md-6">
              <router-link :to="{
                name: 'OfferingInvest',
                params: {
                  id: offering.id
                }
              }" class="btn btn-primary" style="position: absolute, left: 0">create a new investment</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <form @submit.prevent="handleSubmit" @keydown.enter="$event.preventDefault()">
          <h6>New Investment Details</h6>
          <div class="form-outline mb-4 container-fluid">
            <label>Investment Name</label>
            <input type="text" class="form-control" v-model="investment.investmentName" />
          </div>
          <div class="form-outline mb-4 container-fluid">
            <label>Investment Type</label>
            <div class="pt-2">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="investmentType" id="individualInvestment"
                  v-model="investment.investmentType" value="individual" />
                <label class="form-check-label" for="individualInvestment">I will be investing for myself</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="investmentType" id="joinInvestment"
                  v-model="investment.investmentType" value="joint" />
                <label class="form-check-label" for="joinInvestment">I will be investing jointly</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="investmentType" id="entityInvestment"
                  v-model="investment.investmentType" value="entity" />
                <label class="form-check-label" for="joinInvestment">I will be investing for an entity</label>
              </div>
            </div>
          </div>
          <div v-if="investment.investmentType == 'joint' ||
            investment.investmentType == 'individual'
            ">
            <h6 v-if="investment.investmentType == 'joint'">
              Primary Investor
            </h6>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col">
                  <label>First Name</label>
                  <input type="text" v-model="investment.primaryFirstName" class="form-control" required />
                </div>
                <div class="col-md-3">
                  <label>Middle Name</label>
                  <input type="text" v-model="investment.primaryMiddleName" class="form-control" />
                </div>
                <div class="col">
                  <label>Last Name</label>
                  <input type="text" v-model="investment.primaryLastName" class="form-control" required />
                </div>
                <div class="col-2">
                  <label>Suffix</label>
                  <input type="text" v-model="investment.primarySuffix" class="form-control" />
                </div>
              </div>
            </div>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col-md-4">
                  <label>Date of Birth</label>
                  <input type="date" v-model="investment.primaryDateOfBirth" class="form-control" required />
                </div>
                <div class="col-md-4">
                  <label>Phone Number</label>
                  <input type="text" v-model="investment.primaryPhoneNumber" class="form-control" required />
                </div>
                <div v-if="false" class="col-md-4">
                  <label>Alternate Phone</label>
                  <input type="text" v-model="investment.primaryPhoneNumber2" class="form-control" />
                </div>
              </div>
            </div>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="primaryCitizen"
                      v-model="investment.isPrimaryCitizen" />
                    <label class="form-check-label">
                      I am a citizen or resident of the United States with an
                      SSN
                    </label>
                  </div>
                  <div v-if="investment.isPrimaryCitizen">
                    <label>Social Security Number</label>
                    <input type="text" placeholder="###-##-####" v-model="investment.primarySSN" class="form-control"
                      required />
                  </div>
                </div>
                <div class="col">
                  <div v-if="!investment.isPrimaryCitizen" class="form-check">
                    <input class="form-check-input" type="checkbox" id="primaryCitizen"
                      v-model="investment.isPrimaryITIN" />
                    <label class="form-check-label">
                      I have an ITIN
                    </label>
                  </div>
                  <div v-if="!investment.isPrimaryCitizen && investment.isPrimaryITIN
                    ">
                    <label>ITIN</label>
                    <input type="text" placeholder="###-##-####" v-model="investment.primarySSN" class="form-control"
                      required />
                  </div>
                  <div v-if="!investment.isPrimaryCitizen && !investment.isPrimaryITIN
                    ">
                    <p>
                      You can continue investing without an ITIN, but please
                      begin the application process with the IRS as soon as
                      possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <AddressFieldGroup v-model:addressLine1="investment.primaryAddressLine1"
              v-model:addressLine2="investment.primaryAddressLine2" v-model:city="investment.primaryCity"
              v-model:state="investment.primaryState" v-model:postalCode="investment.primaryPostalCode"
              v-model:country="investment.primaryCountry" />
            <div v-if="investment.investmentType == 'joint'">
              <h6>Secondary Investor</h6>
              <div class="form-outline mb-4 container-fluid">
                <div class="row">
                  <div class="col">
                    <label>First Name</label>
                    <input type="text" v-model="investment.secondaryFirstName" class="form-control" required />
                  </div>
                  <div class="col-md-3">
                    <label>Middle Name</label>
                    <input type="text" v-model="investment.secondaryMiddleName" class="form-control" />
                  </div>
                  <div class="col">
                    <label>Last Name</label>
                    <input type="text" v-model="investment.secondaryLastName" class="form-control" required />
                  </div>
                  <div class="col-2">
                    <label>Suffix</label>
                    <input type="text" v-model="investment.secondarySuffix" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="form-outline mb-4 container-fluid">
                <div class="row">
                  <div class="col-md-4">
                    <label>Date of Birth</label>
                    <input type="date" v-model="investment.secondaryDateOfBirth" class="form-control" required />
                  </div>
                  <div class="col-md-4">
                    <label>Phone Number</label>
                    <input type="text" v-model="investment.secondaryPhoneNumber" class="form-control" required />
                  </div>
                  <div class="col-md-4">
                    <label>Email Address</label>
                    <input type="text" v-model="investment.secondaryEmail" class="form-control"
                      @blur="errorSecondaryEmail = validateEmail(investment.secondaryEmail)" required />
                    <div class="error">{{ errorSecondaryEmail }}</div>
                  </div>
                </div>
              </div>
              <div class="form-outline mb-4 container-fluid">
                <div class="row">
                  <div class="col">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="secondaryCitizen"
                        v-model="investment.isSecondaryCitizen" />
                      <label class="form-check-label">
                        2nd investor is a citizen or resident of the United
                        States with an SSN
                      </label>
                    </div>
                    <div v-if="investment.isSecondaryCitizen">
                      <label>Social Security Number</label>
                      <input type="text" placeholder="###-##-####" v-model="investment.secondarySSN" class="form-control"
                        required />
                    </div>
                  </div>
                  <div class="col">
                    <div v-if="!investment.isSecondaryCitizen" class="form-check">
                      <input class="form-check-input" type="checkbox" id="primaryCitizen"
                        v-model="investment.isSecondaryITIN" />
                      <label class="form-check-label">
                        2nd investor has an ITIN
                      </label>
                    </div>
                    <div v-if="!investment.isSecondaryCitizen &&
                      investment.isSecondaryITIN
                      ">
                      <label>ITIN</label>
                      <input type="text" placeholder="###-##-####" v-model="investment.secondarySSN" class="form-control"
                        required />
                    </div>
                    <div v-if="!investment.isSecondaryCitizen &&
                      !investment.isSecondaryITIN
                      ">
                      <p>
                        You can continue investing without an ITIN, but please
                        begin the application process with the IRS as soon as
                        possible.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <AddressFieldGroup v-model:addressLine1="investment.secondaryAddressLine1"
                v-model:addressLine2="investment.secondaryAddressLine2" v-model:city="investment.secondaryCity"
                v-model:state="investment.secondaryState" v-model:postalCode="investment.secondaryPostalCode"
                v-model:country="investment.secondaryCountry" />
            </div>
          </div>
          <div v-if="investment.investmentType == 'entity'">
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col-md">
                  <label>Entity Name</label>
                  <input type="text" v-model="investment.entityName" class="form-control" required />
                </div>
                <div class="col-md">
                  <label>Entity Start Date</label>
                  <input type="date" v-model="investment.entityStartDate" class="form-control" required />
                </div>
              </div>
            </div>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col-md">
                  <label>Entity Type</label>
                  <EntityTypeSelector :selectedType="investment.entityType" @select-type="investment.entityType = $event">
                  </EntityTypeSelector>
                </div>
                <div class="col-md">
                  <label>Entity Industry</label>
                  <EntityIndustrySelector :selectedIndustry="investment.entityIndustry"
                    @select-industry="investment.entityIndustry = $event">
                  </EntityIndustrySelector>
                </div>
              </div>
            </div>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col-md">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="finCenRegulated"
                      v-model="investment.isFinCenRegulated" />
                    <label class="form-check-label">
                      This entity is FinCen Regulated
                    </label>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="usEntity" v-model="investment.isUSEntity" />
                    <label class="form-check-label">
                      This is a US Entity with a TIN/EIN
                    </label>
                  </div>
                  <div v-if="investment.isUSEntity">
                    <label>TIN/EIN</label>
                    <input type="text" placeholder="EIN/TIN" v-model="investment.entityTaxId" class="form-control"
                      required />
                  </div>
                </div>
                <div v-if="!investment.isUSEntity" class="col-md">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="EntityFtin" v-model="investment.isEntityFtin" />
                    <label class="form-check-label">
                      This entity has a FTIN
                    </label>
                  </div>
                  <div v-if="investment.isEntityFtin">
                    <label>Entity FTIN</label>
                    <input type="text" placeholder="FTIN" v-model="investment.entityTaxId" class="form-control"
                      required />
                  </div>
                </div>
              </div>
            </div>
            <h6>Company Address</h6>
            <AddressFieldGroup v-model:addressLine1="investment.entityStreetAddress1"
              v-model:addressLine2="investment.entityStreetAddress2" v-model:city="investment.entityCity"
              v-model:state="investment.entityState" v-model:postalCode="investment.entityPostalCode"
              v-model:country="investment.entityCountry" />
            <h6>Purchaser Representative / Owner Signatory</h6>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col-lg">
                  <label>Representative Name</label>
                  <input type="text" v-model="investment.entityRepresentativeName" class="form-control" required />
                </div>
                <div class="col-lg">
                  <label>Representative Title</label>
                  <input type="text" v-model="investment.entityRepresentativeTitle" class="form-control" required />
                </div>
                <div class="col-lg">
                  <label>Representative Phone</label>
                  <input type="text" v-model="investment.entityRepresentativePhoneNumber" class="form-control" required />
                </div>
                <div class="col-lg">
                  <label>Representative Email</label>
                  <input type="text" v-model="investment.entityRepresentativeEmail" class="form-control"
                    @blur="errorRepresentativeEmail = validateEmail(investment.entityRepresentativeEmail)" required />
                  <div class="error">{{ errorRepresentativeEmail }}</div>
                </div>
              </div>
            </div>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <AddressFieldGroup v-model:addressLine1="investment.entityRepresentativeStreetAddress1
                  " v-model:addressLine2="investment.entityRepresentativeStreetAddress2
    " v-model:city="investment.entityRepresentativeCity" v-model:state="investment.entityRepresentativeState"
                  v-model:postalCode="investment.entityRepresentativePostalCode"
                  v-model:country="investment.entityRepresentativeCountry" />
              </div>
            </div>
            <h6>Owners (> 10% ownership)</h6>
            <div class="form-outline mb-2 container-fluid">
              <div class="row">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="EntityOwnersAccredited"
                    v-model="investment.isEntityOwnersAccredited" />
                  <label class="form-check-label">
                    All owners are accredited
                  </label>
                </div>
              </div>
            </div>
            <div class="form-outline mb-4 container-fluid">
              <div class="row" v-for="(owner, index) in investment.owners" :key="index">
                <div class="row">
                  <div class="col-lg">
                    <label>{{ index + 1 }}. Owner Name</label>
                    <input type="text" v-model="owner.entityOwnerName" class="form-control" required />
                  </div>
                  <div class="col-lg">
                    <label>Owner Date of Birth</label>
                    <input type="date" v-model="owner.entityOwnerDateOfBirth" class="form-control" required />
                  </div>
                  <div class="col-lg">
                    <label>Owner SSN/ITIN</label>
                    <input type="text" v-model="owner.entityOwnerSSN" class="form-control" required />
                  </div>
                  <div class="col-lg">
                    <label>Owner Email Address</label>
                    <input type="text" v-model="owner.entityOwnerEmail" class="form-control"
                      @blur="owner.entityOwnerEmailError = validateEmail(owner.entityOwnerEmail)" required />
                    <div class="error">{{ owner.entityOwnerEmailError }}</div>
                  </div>
                  <div class="col-1 d-flex align-items-center">
                    <span v-if="investment.owners.length > 1" class="btn" @click="handleDeleteOwner(index)">
                      <h3 class="text-danger pt-3">&times;</h3>
                    </span>
                    <button v-else class="btn" disabled>
                      <h3 class="text-secondary pt-3">&times;</h3>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <AddressFieldGroup v-model:addressLine1="owner.entityOwnerStreetAddress1"
                    v-model:addressLine2="owner.entityOwnerStreetAddress2" v-model:city="owner.entityOwnerCity"
                    v-model:state="owner.entityOwnerState" v-model:postalCode="owner.entityOwnerPostalCode"
                    v-model:country="owner.entityOwnerCountry" />
                </div>
              </div>
              <span v-if="investment.owners.length < 10" class="btn btn-primary" @click="handleAddOwner()">
                Add Another Owner
              </span>
              <button v-else class="btn btn-primary" disabled>
                Add Another Owner
              </button>
            </div>
          </div>
          <div v-if="errorOffering">{{ errorOffering }}</div>
          <div v-if="errorInvestment">{{ errorInvestment }}</div>
          <div v-if="errorUpdate">{{ errorUpdate }}</div>
          <div v-if="errorSecondaryEmail && investment.investmentType == 'joint'" class="error">Invalid Secondary Email
          </div>
          <div v-if="errorRepresentativeEmail && investment.investmentType == 'entity'" class="error">Invalid
            Representative Email</div>
          <div class="row text-center">
            <div class="col">
              <router-link :to="{
                name: 'OfferingInvestments',
                params: { id: offering.id }
              }" class="btn btn-secondary">
                Cancel
              </router-link>
            </div>
            <div class="col">
              <div
                v-if="(errorSecondaryEmail && investment.investmentType == 'joint') || (errorRepresentativeEmail && investment.investmentType == 'entity')">
                <button class="btn btn-primary" disabled>
                  Save<i class="bi bi-arrow-right"></i>
                </button>
              </div>
              <div v-else>
                <button class="btn btn-primary">
                  Save<i class="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
  <Footer v-if="investment" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import AddressFieldGroup from "@/components/AddressFieldGroup.vue";
import { ref } from "vue";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";
import EntityTypeSelector from "@/components/EntityTypeSelector.vue";
import EntityIndustrySelector from "@/components/EntityIndustrySelector.vue";

export default {
  props: ["id", "draftId"],
  components: {
    AddressFieldGroup,
    Footer,
    EntityTypeSelector,
    EntityIndustrySelector
  },
  setup(props) {
    const validEmail = ref(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const errorSecondaryEmail = ref(null);
    const errorRepresentativeEmail = ref(null);
    const isPending = ref(false);
    const router = useRouter();
    const { error: errorOffering, document: offering } = getDocument(
      "offerings",
      props.id
    );
    const { error: errorInvestment, document: investment } = getDocument(
      "investmentDrafts",
      props.draftId
    );
    const { error: errorUpdate, updateDoc: updateInvestment } = useDocument(
      "investmentDrafts",
      props.draftId
    );
    const isCancellable = closingDate => {
      if (closingDate) {
        const closingParse = closingDate.split("-");
        const closeDate = new Date(
          closingParse[0],
          closingParse[1] - 1,
          closingParse[2]
        );
        closeDate.setHours(23, 59, 59, 0);
        const timeMilSec = closeDate.getTime() - today.getTime();
        return timeMilSec / (1000 * 60 * 60 * 24) >= 2;
      } else {
        return false;
      }
    };

    const validateEmail = (emailAddress) => {
      let error = null;
      if (emailAddress.length > 0 && !validEmail.value.test(emailAddress)) {
        error = "Invalid Email Address.";
      }
      return error;
    };

    const today = new Date();

    const handleAddOwner = async () => {
      investment.value.owners.push({
        entityOwnerName: "",
        entityOwnerDateOfBirth: "",
        entityOwnerSSN: "",
        entityOwnerEmail: "",
        entityOwnerStreetAddress1: "",
        entityOwnerStreetAddress2: "",
        entityOwnerCity: "",
        entityOwnerState: "",
        entityOwnerPostalCode: "",
        entityOwnerCountry: "",
        entityOwnerEmailError: ""
      });
    };

    const handleDeleteOwner = async index => {
      if (investment.value.owners.length > 1) {
        investment.value.owners.splice(index, 1);
      }
    };

    const handleSubmit = async () => {
      console.log(
        `XXX*ALT PHONE NUMBER NOT BEING STORED*XXX Modify Investment`
      );
      isPending.value = true;
      errorOffering.value = null;
      console.log(`Type: ${investment.value.investmentType} Secondary Email: ${investment.value.secondaryEmail}  Entity Email: ${investment.value.entityRepresentativeEmail}`);
      if (investment.value.investmentType == 'joint') {
        console.log("Joint Investment");
        errorSecondaryEmail.value = validateEmail(investment.value.secondaryEmail);
        console.log(`Error: ${errorSecondaryEmail.value}`);
        if (errorSecondaryEmail.value != null) return;
      }
      if (investment.value.investmentType == 'entity') {
        console.log("Entity Investment");
        errorRepresentativeEmail.value = validateEmail(investment.value.entityRepresentativeEmail);
        console.log(`Error Rep: ${errorRepresentativeEmail.value}`);
        if (errorRepresentativeEmail.value != null) return;
      }
      await updateInvestment({
        entityType: investment.value.entityType,
        entityName: investment.value.entityName,
        entityStartDate: investment.value.entityStartDate,
        entityIndustry: investment.value.entityIndustry,
        isFinCenRegulated: investment.value.isFinCenRegulated,
        isEntityOwnersAccredited: investment.value.isEntityOwnersAccredited,
        isUSEntity: investment.value.isUSEntity,
        isEntityFtin: investment.value.isEntityFtin,
        entityTaxId: investment.value.entityTaxId,
        entityRepresentativeName: investment.value.entityRepresentativeName,
        entityRepresentativeTitle: investment.value.entityRepresentativeTitle,
        entityRepresentativePhoneNumber:
          investment.value.entityRepresentativePhoneNumber,
        entityRepresentativeEmail: investment.value.entityRepresentativeEmail,
        entityRepresentativeStreetAddress1:
          investment.value.entityRepresentativeStreetAddress1,
        entityRepresentativeStreetAddress2:
          investment.value.entityRepresentativeStreetAddress2,
        entityRepresentativeCity: investment.value.entityRepresentativeCity,
        entityRepresentativeState: investment.value.entityRepresentativeState,
        entityRepresentativePostalCode:
          investment.value.entityRepresentativePostalCode,
        entityRepresentativeCountry:
          investment.value.entityRepresentativeCountry,
        entityStreetAddress1: investment.value.entityStreetAddress1,
        entityStreetAddress2: investment.value.entityStreetAddress2,
        entityCity: investment.value.entityCity,
        entityState: investment.value.entityState,
        entityPostalCode: investment.value.entityPostalCode,
        entityCountry: investment.value.entityCountry,
        investmentName: investment.value.investmentName,
        investmentType: investment.value.investmentType,
        primaryFullName: `${investment.value.primaryFirstName
          ? investment.value.primaryFirstName
          : ""
          } ${investment.value.primaryMiddleName
            ? investment.value.primaryMiddleName
            : ""
          } ${investment.value.primaryLastName
            ? investment.value.primaryLastName
            : ""
          }`,
        primaryFirstName: investment.value.primaryFirstName
          ? investment.value.primaryFirstName
          : "",
        primaryMiddleName: investment.value.primaryMiddleName
          ? investment.value.primaryMiddleName
          : "",
        primaryLastName: investment.value.primaryLastName
          ? investment.value.primaryLastName
          : "",
        primarySuffix: investment.value.primarySuffix,
        primaryDateOfBirth: investment.value.primaryDateOfBirth,
        primaryPhoneNumber: investment.value.primaryPhoneNumber,
        primaryPhoneNumber2: null, //investment.value.primaryPhoneNumber2,
        primarySSN: investment.value.primarySSN,
        isPrimaryCitizen: investment.value.isPrimaryCitizen,
        isPrimaryITIN: investment.value.isPrimaryITIN,
        primaryEmail: investment.value.primaryEmail,
        primaryAddressLine1: investment.value.primaryAddressLine1,
        primaryAddressLine2: investment.value.primaryAddressLine2,
        primaryCity: investment.value.primaryCity,
        primaryState: investment.value.primaryState,
        primaryPostalCode: investment.value.primaryPostalCode,
        primaryCountry: investment.value.primaryCountry,
        secondaryFullName: `${investment.value.secondaryFirstName} ${investment.value.secondaryMiddleName
          ? investment.value.secondaryMiddleName
          : ""
          } ${investment.value.secondaryLastName}`,
        secondaryFirstName: investment.value.secondaryFirstName,
        secondaryMiddleName: investment.value.secondaryMiddleName,
        secondaryLastName: investment.value.secondaryLastName,
        secondarySuffix: investment.value.secondarySuffix,
        secondarySSN: investment.value.secondarySSN,
        isSecondaryCitizen: investment.value.isSecondaryCitizen,
        isSecondaryITIN: investment.value.isSecondaryITIN,
        secondaryDateOfBirth: investment.value.secondaryDateOfBirth,
        secondaryPhoneNumber: investment.value.secondaryPhoneNumber,
        secondaryEmail: investment.value.secondaryEmail,
        secondaryAddressLine1: investment.value.secondaryAddressLine1,
        secondaryAddressLine2: investment.value.secondaryAddressLine2,
        secondaryCity: investment.value.secondaryCity,
        secondaryState: investment.value.secondaryState,
        secondaryPostalCode: investment.value.secondaryPostalCode,
        secondaryCountry: investment.value.secondaryCountry,
        owners: investment.value.owners,
        zsDocumentRequestId: "",
        zsDocumentActionId: "",
        editedAt: timestamp()
      });
      if (errorUpdate.value) {
        console.log(`Error: ${errorUpdate.value}`);
      } else {
        router.push({
          name: "InvestmentDetails",
          params: { id: offering.value.id, draftId: props.draftId }
        });
      }
    };

    return {
      isPending,
      offering,
      investment,
      errorInvestment,
      errorUpdate,
      errorOffering,
      errorSecondaryEmail,
      errorRepresentativeEmail,
      isCancellable,
      handleSubmit,
      handleDeleteOwner,
      handleAddOwner,
      validateEmail
    };
  }
};
</script>

<style></style>